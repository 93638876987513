import React, { FC, useEffect, useState } from "react"
import { useMutation, useQuery } from "@apollo/client"
import getSymbolFromCurrency from "currency-symbol-map"
import { useNavigate } from "react-router-dom"
import { RoutePaths } from "../../../AppRoutes"
import { getKpiByName, KpiEntries, KpiTargetType } from "../../../components/forms/KpiSelector/KPI"
import { Option } from "../../../components/forms/SelectInput/SelectBaseUI"
import {
  CampaignKpi,
  CampaignType,
  Fee,
  FeeType,
  KpiType,
  Maybe,
  Platform,
  PlatformCampaignSetupInput,
  PlatformConversionPixel,
} from "../../../generated/graphql"
import { CREATE_MANAGED_CAMPAIGN, GET_PLATFORM_CAMPAIGN, GET_PLATFORM_CAMPAIGNS, GET_PLATFORM_CONVERSION_PIXELS } from "../../../graphql"
import WizardLayout, { WizardStep } from "../../../layouts/Wizard/WizardLayout"
import { logError } from "../../../log"
import { UserProfile } from "../../../models/AuthState"
import { convertDecimalPercentageToPercentage, convertPercentageToDecimalPercentage } from "../../../utils/numberUtils"
import { middleEllipsis } from "../../../utils/stringUtils"
import SetupCampaignData, { Step1Data, Step2Data } from "./SetupCampaignData"
import Step1 from "./Step1"
import Step2 from "./Step2"

const DEFAULT_MARGIN: Fee = {
  min: 0,
  max: 0.2,
  amount: 0.1,
  feeType: FeeType.MarginCostPlus,
}

type CampaignDetails = {
  name: string
  id: string
}

interface CampaignSetupProps {
  user?: UserProfile
  clientId: string
  integrationId: string
  platform: string
  advertiserId: string
  campaignId: string
}

const CampaignSetup: FC<CampaignSetupProps> = ({ user, clientId, integrationId, platform, advertiserId, campaignId }) => {
  const navigate = useNavigate()
  const {
    loading: dataLoading,
    error: dataError,
    data: campaignData,
    called,
  } = useQuery(GET_PLATFORM_CAMPAIGN, {
    fetchPolicy: "network-only",
    variables: {
      clientId: Number(clientId),
      platform,
      platformIntegrationId: Number(integrationId),
      platformAdvertiserId: advertiserId,
      platformCampaignId: campaignId,
    },
  })
  const {
    loading: pixelsLoading,
    error: pixelsError,
    data: pixelsData,
    called: pixelsCalled,
  } = useQuery(GET_PLATFORM_CONVERSION_PIXELS, {
    fetchPolicy: "network-only",
    variables: {
      clientId: Number(clientId),
      platform,
      platformIntegrationId: Number(integrationId),
      platformAdvertiserId: platform === Platform.Facebook ? undefined : advertiserId,
    },
  })
  const [createCampaign, { loading: createLoading, error: createError }] = useMutation(CREATE_MANAGED_CAMPAIGN, {
    refetchQueries: [
      {
        query: GET_PLATFORM_CAMPAIGNS,
        variables: {
          clientId: Number(clientId),
          platform,
          platformIntegrationId: Number(integrationId),
          platformAdvertiserId: platform === Platform.Facebook ? undefined : advertiserId,
        },
      },
    ],
  })

  const [dataFinalised, setDataFinalised] = useState(false)
  const [currencySymbol, setCurrencySymbol] = useState<string>("£")
  const [pixels, setPixels] = useState<Option[]>([])

  const defaultWizardData: SetupCampaignData = [
    {
      campaignType: CampaignType.Display,
      margin: `${DEFAULT_MARGIN.amount * 100}`,
      minMargin: `${(DEFAULT_MARGIN.min || 0) * 100}`,
      maxMargin: `${(DEFAULT_MARGIN.max || 0) * 100}`,
    },
    {
      kpis: [],
    },
  ]

  const [initialData, setInitialData] = useState<SetupCampaignData>(defaultWizardData)
  const [campaignDetails, setCampaignDetails] = useState<CampaignDetails | undefined>(undefined)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const steps: WizardStep<SetupCampaignData, any, any>[] = [
    { title: "Budget Type", component: Step1, props: { dataFinalised, user } }, //
    { title: "KPIs", component: Step2, props: { currencySymbol, createLoading, createError, pixels, platform } },
  ]

  useEffect(() => {
    if (called && !dataLoading && pixelsCalled && !pixelsLoading) {
      setDataFinalised(true)
    }
    if (dataError) {
      // TODO: error state
      logError("ERROR", dataError)
    }
    if (pixelsError) {
      logError("ERROR", pixelsError)
    }
    if (campaignData && campaignData.getPlatformCampaign) {
      setCurrencySymbol(getSymbolFromCurrency(campaignData.getPlatformCampaign.currency) ?? "£")
      setCampaignDetails({
        name: campaignData.getPlatformCampaign.name,
        id: `(${campaignData.getPlatformCampaign.platformCampaignId.id})`,
      })
      const fees = campaignData.getPlatformCampaign.fees as Maybe<Array<Fee>>
      const marginFee = fees?.find((it) => it.feeType === FeeType.MarginCostPlus) ?? {
        ...DEFAULT_MARGIN,
      }
      setInitialData(
        mapExistingData({
          campaignType: (campaignData.getPlatformCampaign.campaignType as CampaignType) ?? CampaignType.Display,
          kpis: (campaignData.getPlatformCampaign.kpis as CampaignKpi[]) ?? [],
          marginFee,
        })
      )
    }
    if (pixelsData && pixelsData.getPlatformConversionPixels) {
      setPixels(
        pixelsData.getPlatformConversionPixels.map((pcp: PlatformConversionPixel) => ({
          label: middleEllipsis(`${pcp.name} - ${pcp.platformConversionPixelId.id}`),
          value: pcp.platformConversionPixelId.id,
        }))
      )
    }
  }, [campaignData, dataError, called, dataLoading, pixelsData, pixelsError, pixelsCalled, pixelsLoading, platform])

  const onComplete = async (data: SetupCampaignData) => {
    await createCampaign({
      variables: {
        clientId: Number(clientId),
        platform,
        platformIntegrationId: Number(integrationId),
        platformAdvertiserId: advertiserId,
        platformCampaignId: campaignId,
        setupInput: buildMutationPayload(data),
      },
    })

    navigate(RoutePaths.campaignsList.resolve(clientId, integrationId, platform, advertiserId))
  }

  return (
    <>
      {dataFinalised && (
        <WizardLayout title={campaignDetails?.name} subTitle={campaignDetails?.id} steps={steps} onComplete={onComplete} initialData={initialData} />
      )}
    </>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const buildMutationPayload = (data: SetupCampaignData): PlatformCampaignSetupInput => {
  const step1Data = data["0"] as Step1Data
  const step2Data = data["1"] as Step2Data

  return {
    campaignType: step1Data.campaignType,
    fees: [
      {
        feeType: FeeType.MarginCostPlus,
        amount: Number(convertPercentageToDecimalPercentage(step1Data.margin)),
        min: step1Data.minMargin ? Number(convertPercentageToDecimalPercentage(step1Data.minMargin)) : undefined,
        max: step1Data.maxMargin ? Number(convertPercentageToDecimalPercentage(step1Data.maxMargin)) : undefined,
      },
    ],
    kpis: step2Data.kpis.map((kpi) => {
      let target = Number(kpi.target)
      let min = Number(kpi.minTarget)
      let max = Number(kpi.maxTarget)

      if (kpi.kpi.targetType === KpiTargetType.Percentage) {
        target = Number(convertPercentageToDecimalPercentage(kpi.target))
        min = Number(convertPercentageToDecimalPercentage(kpi.minTarget))
        max = Number(convertPercentageToDecimalPercentage(kpi.maxTarget))
      }

      return {
        type: kpi.type as KpiType,
        name: kpi.kpi.name,
        target,
        min,
        max,
        weight: Number(kpi.weight),
        pixelIds: kpi.pixelIds ?? undefined,
        minimise: kpi.kpi.minimise,
      }
    }),
  }
}

type MapExistingDataOpts = {
  campaignType: CampaignType
  kpis: CampaignKpi[]
  marginFee: Fee
}

const mapExistingData = (opts: MapExistingDataOpts): SetupCampaignData => {
  const { campaignType, kpis, marginFee } = opts
  return [
    // Step 1 Data
    {
      campaignType: campaignType,
      margin: convertDecimalPercentageToPercentage({ value: marginFee.amount }) ?? "",
      minMargin: convertDecimalPercentageToPercentage({ value: marginFee.min ?? undefined }) ?? "",
      maxMargin: convertDecimalPercentageToPercentage({ value: marginFee.max ?? undefined }) ?? "",
    },
    // Step 2 Data
    {
      kpis: kpis.map((kpi) => {
        const kpiEntry = getKpiByName(KpiEntries, kpi.name)

        let target = kpi.target.toString()
        let minTarget = kpi.min.toString()
        let maxTarget = kpi.max.toString()

        if (kpiEntry.targetType === KpiTargetType.Percentage) {
          target = convertDecimalPercentageToPercentage({ value: kpi.target }) ?? ""
          minTarget = convertDecimalPercentageToPercentage({ value: kpi.min }) ?? ""
          maxTarget = convertDecimalPercentageToPercentage({ value: kpi.max }) ?? ""
        }

        return {
          type: kpi.type,
          kpi: kpiEntry,
          target,
          minTarget,
          maxTarget,
          pixelIds: kpi.pixelIds ?? undefined,
          weight: kpi.weight.toString(),
        }
      }),
    },
  ]
}

export default CampaignSetup
