import { FC } from "react"
import { InfoIcon } from "@chakra-ui/icons"
import { HStack, Text, Tooltip, VStack } from "@chakra-ui/react"
import { CampaignType, MetricName } from "../../generated/graphql"
import { TileProps } from "../../layouts/TilesGrid"
import GridState from "../../layouts/TilesGrid/GridState"
import CampaignDashboardStateController from "../../pages/CampaignDashboardPage/types"
import colors from "../../theme/colors"
import Tile from "../Tile"
import MetricsStripLoader from "./MetricsStripLoader"
import { MetricDataSet } from "./types"
import { extractPixelNamesFromPixels, mapMetricNameToDisplayName } from "./utils"

const breakElementsIntoRows = (elements: JSX.Element[], itemsPerRow: number, numberOfRows: number) => {
  const rows = []

  for (let i = 0; i < numberOfRows; i++) {
    rows.push(
      <HStack key={`row-${i}`} w="100%" justifyContent="space-between">
        {elements.slice(i * itemsPerRow, i * itemsPerRow + itemsPerRow)}
      </HStack>
    )
  }

  return rows
}

const MetricsStrip: FC<TileProps<CampaignDashboardStateController, GridState, MetricDataSet[]>> = ({ tileData, queryDetails, dashboardController }) => {
  const itemsPerRow = dashboardController.campaignType === CampaignType.Youtube ? 4 : 6

  const numberOfRows = Math.ceil(tileData?.length / itemsPerRow)

  const itemWidth = 100 / itemsPerRow

  const metrics = tileData.map((metricDataSet, index) => {
    const { name, data, pixels } = metricDataSet
    const pixelNames = extractPixelNamesFromPixels(pixels)
    const displayPixelNameAsHeader = name === MetricName.Conversions && pixels?.length === 1

    const pixelsTooltipLabel = (displayPixelNameAsHeader: boolean) =>
      displayPixelNameAsHeader && pixelNames.length < 18 ? undefined : (
        <>
          {pixelNames.split(",").map((pixelName) => (
            <Text w="fit-content" fontSize="0.9rem" whiteSpace="nowrap">
              {displayPixelNameAsHeader ? pixelName : `- ${pixelName}`}
            </Text>
          ))}
        </>
      )

    return (
      <VStack key={`${name}-${index}`} alignContent="start" width={`${itemWidth}%`}>
        {displayPixelNameAsHeader ? (
          <HStack w="100%">
            <Tooltip aria-label={pixelNames} label={pixelsTooltipLabel(true)} placement="right-start">
              <Text as="b" color="brand.400" w="100%" whiteSpace="nowrap" overflow="hidden" textAlign="center" textOverflow="ellipsis">
                {pixelNames}
              </Text>
            </Tooltip>
          </HStack>
        ) : (
          <HStack>
            <Text as="b" color="brand.400" w="100%" whiteSpace="nowrap" overflow="hidden" textAlign="center" textOverflow="ellipsis">
              {mapMetricNameToDisplayName[name]}
            </Text>
            {pixelNames.length > 0 && (
              <Tooltip aria-label={pixelNames} label={pixelsTooltipLabel(false)} placement="right-start" sx={{ boxShadow: "unset" }}>
                <InfoIcon color={"brand.400"} w={4} h={4} display="flex" alignSelf="flex-start" />
              </Tooltip>
            )}
          </HStack>
        )}
        <Text fontSize="lg">{data.length ? data[0].formattedValue : "No Data"}</Text>
      </VStack>
    )
  })

  return (
    <Tile
      loading={queryDetails?.loading}
      loader={<MetricsStripLoader />}
      error={queryDetails?.error}
      overflowY="scroll"
      maxH="224px"
      sx={{
        "::-webkit-scrollbar": {
          width: "4px",
          height: "0px",
        },
        "::-webkit-scrollbar-track": {
          background: colors.colors.brand["700"],
          // marginTop: "24px",
        },
        "::-webkit-scrollbar-thumb": {
          background: colors.colors.brand["200"],
        },
        "::-webkit-scrollbar-thumb:hover": {
          background: "#555",
        },
      }}
    >
      <VStack w="100%" h="fit-content">
        {!queryDetails?.loading && breakElementsIntoRows(metrics, itemsPerRow, numberOfRows)}
      </VStack>
    </Tile>
  )
}

export default MetricsStrip
