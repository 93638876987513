import { CampaignType, KpiName, KpiType } from "../../../generated/graphql"
import { Step2Data } from "../../../pages/CampaignSetupPage/CampaignSetup/SetupCampaignData"
import { Optional, StringKeyObject } from "../../../types"
import { Option } from "../SelectInput/SelectBaseUI"

export enum KpiTargetType {
  Integer = "INTEGER",
  Monetary = "MONETARY",
  Percentage = "PERCENTAGE",
}

export const mapKpiNameToDisplayName: Record<KpiName, string> = {
  [KpiName.Cpa]: "CPA - Cost Per Action",
  [KpiName.Cpc]: "CPC - Cost Per Click",
  [KpiName.Cpcv]: "CPCV - Cost Per Completed View",
  [KpiName.Cpm]: "CPM - Cost Per Thousand Impressions",
  [KpiName.Cpv]: "CPV - Cost Per View",
  [KpiName.Ctr]: "CTR - Click Through Rate",
  [KpiName.Reach]: "Reach",
  [KpiName.Roas]: "ROAS - Return on Ad Spend",
  [KpiName.Vcpm]: "VCPM - Cost Per Viewable Thousand Impressions",
  [KpiName.Vcr]: "VCR - Video Completed Rate",
  [KpiName.Viewability]: "Viewability",
}

export interface FormKpiTarget {
  integer?: string
  monetary?: string
  percentage?: string
}

interface FormKpiConstraint {
  target?: FormKpiTarget
}

interface FormKpiConstraints {
  min: FormKpiConstraint
  max: FormKpiConstraint
}

export interface FormKpi {
  name?: string
  target?: FormKpiTarget
  constraints?: FormKpiConstraints
  weight?: string
  pixelIds?: string[] | string
}

export interface FormValues {
  mainKpi?: FormKpi
  secondaryKpis?: Array<FormKpi>
}

export interface KpiStepData {
  type: string
  kpi: KpiEntry
  target: string
  minTarget: string
  maxTarget: string
  pixelIds?: string[]
  weight: string
}

export type KpiEntry = {
  name: KpiName
  targetType: KpiTargetType
  requiresPixelId: boolean
  minimise: boolean
  campaignType?: CampaignType[]
}

const KpiEntriesByRecord: Record<KpiName, KpiEntry> = {
  [KpiName.Cpv]: {
    name: KpiName.Cpv,
    targetType: KpiTargetType.Monetary,
    requiresPixelId: false,
    minimise: true,
    campaignType: [CampaignType.Video, CampaignType.Youtube],
  },
  [KpiName.Cpm]: {
    name: KpiName.Cpm,
    targetType: KpiTargetType.Monetary,
    requiresPixelId: false,
    minimise: true,
  },
  [KpiName.Vcpm]: {
    name: KpiName.Vcpm,
    targetType: KpiTargetType.Monetary,
    requiresPixelId: false,
    minimise: true,
    campaignType: [CampaignType.Display],
  },
  [KpiName.Cpa]: {
    name: KpiName.Cpa,
    targetType: KpiTargetType.Monetary,
    requiresPixelId: true,
    minimise: true,
  },
  [KpiName.Viewability]: {
    name: KpiName.Viewability,
    targetType: KpiTargetType.Percentage,
    requiresPixelId: false,
    minimise: false,
  },
  [KpiName.Cpc]: {
    name: KpiName.Cpc,
    targetType: KpiTargetType.Monetary,
    requiresPixelId: false,
    minimise: true,
  },
  [KpiName.Ctr]: {
    name: KpiName.Ctr,
    targetType: KpiTargetType.Percentage,
    requiresPixelId: false,
    minimise: false,
  },
  [KpiName.Cpcv]: {
    name: KpiName.Cpcv,
    targetType: KpiTargetType.Monetary,
    requiresPixelId: false,
    minimise: true,
    campaignType: [CampaignType.Video, CampaignType.Youtube],
  },
  [KpiName.Vcr]: {
    name: KpiName.Vcr,
    targetType: KpiTargetType.Percentage,
    requiresPixelId: false,
    minimise: false,
    campaignType: [CampaignType.Video, CampaignType.Youtube],
  },
  [KpiName.Reach]: {
    name: KpiName.Reach,
    targetType: KpiTargetType.Integer,
    requiresPixelId: false,
    campaignType: [CampaignType.Youtube],
    minimise: false,
  },
  [KpiName.Roas]: {
    name: KpiName.Roas,
    targetType: KpiTargetType.Percentage,
    requiresPixelId: false,
    minimise: false,
  },
}

export const KpiEntries = Object.values(KpiEntriesByRecord)

export const getKpiByName = (kpis: KpiEntry[], searchKpi: string | Option): KpiEntry => {
  let result: Optional<KpiEntry> = undefined
  if (typeof searchKpi === "string") {
    result = kpis.find((kpi) => kpi.name.toLowerCase() === searchKpi.toLowerCase())
  } else {
    result = kpis.find((kpi) => kpi.name.toLowerCase() === searchKpi.value.toLowerCase())
  }
  if (result) return result
  throw Error(`No KPI with name: '${searchKpi}'`)
}

export const buildInitialValueKpi = (kpiStepData: Optional<KpiStepData>): FormKpi => {
  const targetBlock = (integer: string, percentage: string, monetary: string) => ({
    integer,
    percentage,
    monetary,
  })

  if (!kpiStepData) {
    return {
      name: undefined,
      target: targetBlock("", "", ""),
      constraints: {
        min: { target: targetBlock("", "", "") },
        max: { target: targetBlock("", "", "") },
      },
      weight: undefined,
      pixelIds: undefined,
    }
  }

  const buildTargetBlock = (kpi: KpiEntry, value: string) => {
    switch (kpi.targetType) {
      case KpiTargetType.Integer:
        return targetBlock(value, "", "")
      case KpiTargetType.Percentage:
        return targetBlock("", value, "")
      case KpiTargetType.Monetary:
        return targetBlock("", "", value)
      default:
        throw Error(`Unknown valueType: '${kpi.targetType}'`)
    }
  }

  const { kpi, target, minTarget, maxTarget, weight, pixelIds } = kpiStepData

  return {
    name: kpi.name,
    target: buildTargetBlock(kpi, target),
    constraints: {
      min: { target: minTarget ? buildTargetBlock(kpi, minTarget) : targetBlock("", "", "") },
      max: { target: maxTarget ? buildTargetBlock(kpi, maxTarget) : targetBlock("", "", "") },
    },
    weight,
    pixelIds,
  }
}

export const buildInitialFormValues = (kpis: KpiEntry[], stepData: Step2Data): FormValues => {
  const mainKpi = stepData?.kpis.find((kpi) => kpi.type === KpiType.Main)
  const secondaryKpis = stepData?.kpis.filter((kpi) => kpi.type === KpiType.Secondary) || []
  return {
    mainKpi: buildInitialValueKpi(mainKpi),
    secondaryKpis: secondaryKpis //
      .map((kpi) => buildInitialValueKpi(kpi) as FormKpi)
      .filter((kpi) => kpi.name !== undefined),
  }
}

export const buildKpiStepData = (kpis: KpiEntry[], kpiValues: FormKpi, type: KpiType): KpiStepData => {
  // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
  const kpi = getKpiByName(kpis, kpiValues.name!)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parseValue = (valueBlock?: StringKeyObject<any>): string => {
    if (kpi.targetType === KpiTargetType.Monetary) {
      if (valueBlock?.monetary === "" || valueBlock?.monetary === "0") {
        return ""
      } else {
        return parseFloat(valueBlock?.monetary).toString()
      }
    } else if (kpi.targetType === KpiTargetType.Percentage) {
      if (valueBlock?.percentage === "" || valueBlock?.percentage === "0") {
        return ""
      } else {
        return parseFloat(valueBlock?.percentage).toString()
      }
    } else if (kpi.targetType === KpiTargetType.Integer) {
      if (valueBlock?.integer === "" || valueBlock?.integer === "0") {
        return ""
      } else {
        return parseFloat(valueBlock?.integer).toString()
      }
    }
    return ""
  }

  const pixelIds =
    typeof kpiValues.pixelIds === "string"
      ? kpiValues.pixelIds
          .split(",")
          .map((pixelId) => pixelId.trim())
          .filter((pixelId) => pixelId !== "")
      : (kpiValues.pixelIds ?? []).filter((pixelId) => pixelId !== "")

  return {
    kpi,
    type,
    target: parseValue(kpiValues.target) ?? "",
    minTarget: parseValue(kpiValues.constraints?.min?.target) ?? "",
    maxTarget: parseValue(kpiValues.constraints?.max?.target) ?? "",
    weight: kpiValues.weight ?? "",
    pixelIds: kpiValues.name === KpiName.Cpa ? pixelIds : undefined,
  }
}

export const buildStepData = (kpis: KpiEntry[], values: FormValues): Step2Data => {
  if (values.mainKpi?.name === undefined) {
    return {
      kpis: [],
    }
  }
  if (values.secondaryKpis) {
    return {
      // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
      kpis: [buildKpiStepData(kpis, values.mainKpi!, KpiType.Main)].concat(
        values.secondaryKpis?.filter((kpi) => kpi.name).map((kpi) => buildKpiStepData(kpis, kpi, KpiType.Secondary))
      ),
    }
  }
  return {
    // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
    kpis: [buildKpiStepData(kpis, values.mainKpi!, KpiType.Main)],
  }
}
