import { FC } from "react"
import { ApolloError } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import ErrorText from "../../../components/forms/ErrorText"
import SelectInput from "../../../components/forms/SelectInput"
import TextInput from "../../../components/forms/TextInput"
import WizardNavigateNextButton from "../../../components/WizardNavigateNextButton"
import WizardNavigatePreviousButton from "../../../components/WizardNavigatePreviousButton"
import { Client, ClientType } from "../../../generated/graphql"
import mapErrorTypeToMessage from "../../../graphql/errors"
import { CustomGraphQLError, Optional } from "../../../types"
import { enumToLabelValueArray } from "../../../utils/enumUtils"

export interface FormValues {
  clientId?: number
  name?: string
  type?: ClientType
  clientContact?: string
  billingContact?: string
}

const schema = z
  .object({
    name: z.string().trim(),
    type: z.nativeEnum(ClientType),
    clientContact: z.string().trim().email(),
    billingContact: z.string().trim().email(),
  })
  .required()

type ClientSetupFormProps = {
  error?: ApolloError
  data?: Client
  onSubmitHandler: (data: FormValues) => void
  fieldsState: {
    clientName?: string
  }
}

const ClientSetupForm: FC<ClientSetupFormProps> = ({ error, data, fieldsState, onSubmitHandler }) => {
  const initialValues = getInitialFormValues(data)

  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      ...initialValues,
    },
    resolver: zodResolver(schema),
  })

  const errorType = error && error.graphQLErrors ? (error?.graphQLErrors[0] as CustomGraphQLError).errorType : undefined

  return (
    <>
      <WizardNavigatePreviousButton previous={{ shown: false }} />
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <TextInput label="Name" name="name" control={control} />
        <SelectInput
          label="Client Type"
          name="type"
          options={enumToLabelValueArray(ClientType).filter((item) => item.value !== ClientType.Fifty9AClient)}
          control={control}
          isInModal={true}
          zIndex={9}
        />
        <TextInput label="Client Contact" name="clientContact" control={control} tooltip="This must be a valid email" />
        <TextInput label="Billing Contact" name="billingContact" control={control} tooltip="This must be a valid email" />
        <ErrorText
          height={10}
          message={
            errorType
              ? mapErrorTypeToMessage({
                  errorType,
                  error,
                  name: fieldsState.clientName,
                })
              : ""
          }
          size="small"
        />
        <WizardNavigateNextButton next={{ text: "Submit", type: "submit" }} />
      </form>
    </>
  )
}

const getInitialFormValues = (data: Optional<Client>): FormValues => {
  return {
    clientId: data?.clientId,
    name: data?.name,
    type: data?.__typename ? ClientType[data?.__typename] : ClientType.Fifty9AClient,
  }
}

export default ClientSetupForm
