import { graphql } from "msw"

export const enableAutoApproveCampaign = graphql.mutation("EnableAutoApproveCampaign", (req, res, ctx) => {
  return res(
    ctx.data({
      enableAutoApproveCampaign: {
        __typename: "PlatformCampaign",
        id: "3-DV360-1011521794",
        clientId: 1,
        platform: "DV360",
        platformAdvertiserId: {
          __typename: "PlatformAdvertiserId",
          id: "1231378503",
          platform: "DV360",
        },
        platformCampaignId: {
          __typename: "PlatformCampaignId",
          id: "1011521794",
          platform: "DV360",
        },
        name: "UK_Hamilton_CY23_Q2_DISPLAY_RTG",
        currency: "",
        managed: true,
        kpis: [
          {
            type: "MAIN",
            name: "CPM",
            target: 3.0,
            min: 2.0,
            max: 5.0,
            weight: 9,
            pixelIds: null,
            minimise: true,
            __typename: "CampaignKPI",
          },
        ],
      },
    })
  )
})
