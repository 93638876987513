import React, { FC, useCallback, useEffect, useMemo, useState } from "react"
import { useLazyQuery, useMutation } from "@apollo/client"
import { Grid, GridItem, VStack } from "@chakra-ui/react"
import getSymbolFromCurrency from "currency-symbol-map"
import { format, subDays } from "date-fns"
import { ErrorBoundary } from "react-error-boundary"
import { useLocation, useNavigate } from "react-router-dom"
import { RoutePaths } from "../../AppRoutes"
import Button from "../../components/Button"
import MetricsStrip from "../../components/dataVisualisation/MetricsStrip"
import SelectableMetricsGraph from "../../components/dataVisualisation/SelectableMetricsGraph/SelectableMetricsGraph"
import TablesInTabs from "../../components/dataVisualisation/TablesInTabs"
import TabsLists from "../../components/dataVisualisation/TabsLists"
import TrendsStrip from "../../components/dataVisualisation/TrendsStrip"
import { SegmentType } from "../../components/dataVisualisation/types"
import {
  getSelectableMetrics,
  unPackDailyMetricsForTrendGraphs,
  unPackItemisedMetricsForTables,
  unPackMetricsForMetricsStrip,
} from "../../components/dataVisualisation/utils"
import ErrorFallBack from "../../components/ErrorFallback"
import Loading from "../../components/Loading"
import RangeDatePicker from "../../components/RangeDatePicker"
import Tile from "../../components/Tile"
import {
  CampaignItemisedAggregatedMetrics,
  CampaignKpi,
  CampaignType,
  MetricName,
  Platform,
  PlatformCampaign,
  PlatformCreative,
  PlatformLineItem,
} from "../../generated/graphql"
import {
  DISABLE_CAMPAIGN_MANAGEMENT,
  ENABLE_CAMPAIGN_MANAGEMENT,
  GET_CAMPAIGN_AGGREGATED_METRICS,
  GET_CAMPAIGN_AGGREGATED_METRICS_BY_CREATIVE,
  GET_CAMPAIGN_AGGREGATED_METRICS_BY_LINE_ITEM,
  GET_CAMPAIGN_CREATIVES_AGGREGATED_METRICS,
  GET_CAMPAIGN_CREATIVES_DAILY_METRICS,
  GET_CAMPAIGN_CREATIVES_WITH_DELIVERY,
  GET_CAMPAIGN_DAILY_METRICS,
  GET_CAMPAIGN_LAST_7_DAILY_METRICS,
  GET_CAMPAIGN_LINE_ITEMS_AGGREGATED_METRICS,
  GET_CAMPAIGN_LINE_ITEMS_DAILY_METRICS,
  GET_CAMPAIGN_LINE_ITEMS_WITH_DELIVERY,
  GET_PLATFORM_CAMPAIGN,
} from "../../graphql"
import { useCampaignParams } from "../../hooks/navigationHooks"
import useAuth from "../../hooks/useAuth"
import usePermissions, { canView } from "../../hooks/usePermissions"
import useResourceUris from "../../hooks/useResourceUris"
import MainLayout, { BreadcrumbItemProps } from "../../layouts/Main"
import { TileComponent, TilesGridLayout, TileProps } from "../../layouts/TilesGrid"
import GridState, { exampleGridState } from "../../layouts/TilesGrid/GridState"
import { logUnhandledError } from "../../log"
import { StringKeyObject } from "../../types"
import { enumEntryFromString } from "../../utils/enumUtils"
import AuthenticatedProps from "../AuthenticatedProps"
import CampaignDashboardNoData from "./components/CampaignDashboardNoData"
import CampaignInfo from "./components/CampaignInfo"
import CampaignDashboardStateController, {
  CampaignDashboardStateItems,
  defaultCampaignDashboardState,
  SelectedSegmentsListChangeProps,
  SelectedSegmentsTableChangeProps,
} from "./types"

type DashboardProps = AuthenticatedProps

type LocationState = {
  name?: string
  currency?: string
  managed?: boolean
  campaignType?: CampaignType
  kpis?: CampaignKpi[]
}

const validateLocationState = (state: unknown): LocationState | undefined => {
  if (!state) {
    return undefined
  }
  return state
}

const CampaignDashboardPage: FC<DashboardProps> = ({ signOut }) => {
  const { user } = useAuth()
  const { platformCampaignResourceUri } = useResourceUris()
  const { permissions, loading: permissionsLoading } = usePermissions()

  const location = useLocation()
  const locationState = validateLocationState(location.state)

  const navigate = useNavigate()
  const { clientId: paramsClientId, integrationId, platform: paramsPlatform, advertiserId, campaignId } = useCampaignParams()

  const breadcrumbItems: BreadcrumbItemProps[] = [
    { text: "Integrations", onClick: () => navigate(RoutePaths.integrationsList.resolve(clientId)) },
    { text: integrationId, onClick: () => navigate(RoutePaths.advertisersList.resolve(clientId, integrationId)) },
    { text: "Advertisers", onClick: () => navigate(RoutePaths.advertisersList.resolve(clientId, integrationId)) },
    {
      text: advertiserId,
      onClick: () => navigate(RoutePaths.campaignsList.resolve(clientId, integrationId, platform, advertiserId)),
    },
    {
      text: "Campaigns",
      onClick: () => navigate(RoutePaths.campaignsList.resolve(clientId, integrationId, platform, advertiserId)),
    },
    {
      text: campaignId,
    },
    { text: "Dashboard" },
  ]
  if (user?.is59A) {
    breadcrumbItems.splice(0, 0, {
      text: "Clients",
      onClick: () => navigate(RoutePaths.clientsList.resolve()),
    })
    breadcrumbItems.splice(1, 0, {
      text: paramsClientId,
      onClick: () => navigate(RoutePaths.integrationsList.resolve(paramsClientId)),
    })
  }

  const clientId = paramsClientId ?? user?.clientId
  const platform = paramsPlatform ? enumEntryFromString(Platform, paramsPlatform) : Platform.Xandr

  const [selectedDates, setSelectedDates] = useState<Date[]>([subDays(new Date(), 29), new Date()])
  const [setupDataLoaded, setSetupDataLoaded] = useState(false)

  const [loadCampaign, { loading: campaignLoading, error: campaignError, data: campaignData, called: campaignCalled }] = useLazyQuery(GET_PLATFORM_CAMPAIGN)

  useEffect(() => {
    if (!locationState && !campaignCalled && clientId && integrationId && platform && advertiserId && campaignId) {
      loadCampaign({
        fetchPolicy: "network-only",
        variables: {
          clientId: Number(clientId),
          platformIntegrationId: Number(integrationId),
          platform,
          platformAdvertiserId: advertiserId,
          platformCampaignId: campaignId,
        },
      }).then()
    }
  }, [locationState, campaignCalled, clientId, integrationId, platform, advertiserId, campaignId, loadCampaign])

  const name = useMemo(() => {
    if (locationState?.name) {
      return locationState?.name
    }
    if (campaignCalled && campaignData) {
      return (campaignData.getPlatformCampaign as PlatformCampaign).name
    }
  }, [locationState, campaignCalled, campaignData])

  const currency = useMemo(() => {
    if (locationState?.currency) {
      return locationState?.currency
    }
    if (campaignCalled && campaignData) {
      return (campaignData.getPlatformCampaign as PlatformCampaign).currency
    }
  }, [locationState, campaignCalled, campaignData])

  const managed = useMemo(() => {
    if (locationState?.managed) {
      return locationState?.managed
    }
    if (campaignCalled && campaignData) {
      return (campaignData.getPlatformCampaign as PlatformCampaign).managed
    }
  }, [locationState, campaignCalled, campaignData])

  const campaignType = useMemo(() => {
    if (locationState?.campaignType) {
      return locationState?.campaignType
    }
    if (campaignCalled && campaignData) {
      return (campaignData.getPlatformCampaign as PlatformCampaign).campaignType ?? undefined
    }
  }, [locationState, campaignCalled, campaignData])

  const kpis = useMemo(() => {
    if (locationState?.kpis) {
      return locationState?.kpis
    }
    if (campaignCalled && campaignData) {
      return (campaignData.getPlatformCampaign as PlatformCampaign).kpis ?? undefined
    }
  }, [locationState, campaignCalled, campaignData])

  const pixelIdsSets = useMemo(() => {
    return kpis ? kpis.filter((kpi: CampaignKpi) => kpi.name === MetricName.Cpa).map((kpi) => kpi.pixelIds ?? []) ?? [] : []
  }, [kpis])

  const [
    loadCampaignCreativesWithDelivery,
    {
      data: campaignCreativesWithDeliveryData,
      loading: campaignCreativesWithDeliveryLoading,
      error: campaignCreativesWithDeliveryError,
      called: campaignCreativesWithDeliveryCalled,
    },
  ] = useLazyQuery(GET_CAMPAIGN_CREATIVES_WITH_DELIVERY)

  const [
    loadCampaignLineItemsWithDelivery,
    {
      data: campaignLineItemsWithDeliveryData,
      loading: campaignLineItemsWithDeliveryLoading,
      error: campaignLineItemsWithDeliveryError,
      called: campaignLineItemsWithDeliveryCalled,
    },
  ] = useLazyQuery(GET_CAMPAIGN_LINE_ITEMS_WITH_DELIVERY)

  useEffect(() => {
    const loadSetupData = async () => {
      await Promise.all([
        loadCampaignCreativesWithDelivery({
          fetchPolicy: "network-only",
          variables: {
            clientId: Number(clientId),
            platformIntegrationId: Number(integrationId),
            platform,
            platformAdvertiserId: advertiserId,
            platformCampaignId: campaignId,
            dateFrom: format(selectedDates[0], "yyyy-MM-dd"),
            dateTo: format(selectedDates[1], "yyyy-MM-dd"),
          },
        }).then(),
        loadCampaignLineItemsWithDelivery({
          fetchPolicy: "network-only",
          variables: {
            clientId: Number(clientId),
            platformIntegrationId: Number(integrationId),
            platform,
            platformAdvertiserId: advertiserId,
            platformCampaignId: campaignId,
            dateFrom: format(selectedDates[0], "yyyy-MM-dd"),
            dateTo: format(selectedDates[1], "yyyy-MM-dd"),
          },
        }).then(),
      ])
    }

    if (selectedDates.length === 2 && !campaignCreativesWithDeliveryCalled && !campaignLineItemsWithDeliveryCalled) {
      loadSetupData().then()
    }
  }, [
    selectedDates,
    clientId,
    platform,
    integrationId,
    advertiserId,
    campaignId,
    loadCampaignCreativesWithDelivery,
    loadCampaignLineItemsWithDelivery,
    campaignCreativesWithDeliveryCalled,
    campaignLineItemsWithDeliveryCalled,
  ])

  const creativesWithData = useMemo(() => {
    if (campaignCreativesWithDeliveryCalled && campaignCreativesWithDeliveryData) {
      return campaignCreativesWithDeliveryData.getCampaignCreativesWithDelivery as PlatformCreative[]
    }
    return []
  }, [campaignCreativesWithDeliveryCalled, campaignCreativesWithDeliveryData])

  const lineItemsWithData = useMemo(() => {
    if (campaignLineItemsWithDeliveryCalled && campaignLineItemsWithDeliveryData) {
      return campaignLineItemsWithDeliveryData.getCampaignLineItemsWithDelivery as PlatformLineItem[]
    }
    return []
  }, [campaignLineItemsWithDeliveryCalled, campaignLineItemsWithDeliveryData])

  const selectableMetrics = useMemo(() => {
    if (kpis && currency) {
      return getSelectableMetrics(kpis, campaignType ?? CampaignType.Display, getSymbolFromCurrency(currency) ?? "£")
    }
    return []
  }, [kpis, campaignType, currency])

  const [disableCampaignManagement, { loading: disableCampaignManagementLoading, error: disableCampaignManagementError }] = useMutation(
    DISABLE_CAMPAIGN_MANAGEMENT,
    {
      refetchQueries: [GET_PLATFORM_CAMPAIGN],
    }
  )
  const [enableCampaignManagement, { loading: enableCampaignManagementLoading, error: enableCampaignManagementError }] = useMutation(
    ENABLE_CAMPAIGN_MANAGEMENT,
    {
      refetchQueries: [GET_PLATFORM_CAMPAIGN],
    }
  )

  const handleToggleManageCampaign = async () => {
    if (managed) {
      await disableCampaignManagement({
        variables: {
          clientId: Number(clientId),
          platform: paramsPlatform,
          platformIntegrationId: Number(integrationId),
          platformAdvertiserId: advertiserId,
          platformCampaignId: campaignId,
        },
      })
    } else {
      await enableCampaignManagement({
        variables: {
          clientId: Number(clientId),
          platform: paramsPlatform,
          platformIntegrationId: Number(integrationId),
          platformAdvertiserId: advertiserId,
          platformCampaignId: campaignId,
        },
      })
    }
  }

  useEffect(() => {
    if (
      !(
        campaignLoading ||
        disableCampaignManagementLoading ||
        enableCampaignManagementLoading ||
        campaignCreativesWithDeliveryLoading ||
        campaignLineItemsWithDeliveryLoading
      ) &&
      campaignCreativesWithDeliveryCalled &&
      campaignLineItemsWithDeliveryCalled &&
      creativesWithData &&
      lineItemsWithData
    ) {
      setSetupDataLoaded(true)
    }
  }, [
    campaignLoading,
    disableCampaignManagementLoading,
    enableCampaignManagementLoading,
    campaignCreativesWithDeliveryLoading,
    campaignLineItemsWithDeliveryLoading,
    campaignCreativesWithDeliveryCalled,
    campaignLineItemsWithDeliveryCalled,
    creativesWithData,
    lineItemsWithData,
  ])

  const errored = Boolean(enableCampaignManagementError) || Boolean(disableCampaignManagementError)
  const setupDataErrored = Boolean(campaignError) || Boolean(campaignCreativesWithDeliveryError) || Boolean(campaignLineItemsWithDeliveryError)

  const [
    loadCampaignLast7DailyMetrics,
    {
      data: campaignLast7DailyMetricsData,
      loading: campaignLast7DailyMetricsLoading,
      error: campaignLast7DailyMetricsError,
      called: campaignLast7DailyMetricsCalled,
    },
  ] = useLazyQuery(GET_CAMPAIGN_LAST_7_DAILY_METRICS)

  const [
    loadCampaignAggregatedMetrics,
    {
      called: campaignAggregatedMetricsCalled,
      loading: campaignAggregatedMetricsLoading,
      error: campaignAggregatedMetricsError,
      data: campaignAggregatedMetricsData,
    },
  ] = useLazyQuery(GET_CAMPAIGN_AGGREGATED_METRICS)

  const [
    loadCampaignAggregatedMetricsByCreative,
    {
      called: campaignAggregatedMetricsByCreativeCalled,
      loading: campaignAggregatedMetricsByCreativeLoading,
      error: campaignAggregatedMetricsByCreativeError,
      data: campaignAggregatedMetricsByCreativeData,
    },
  ] = useLazyQuery(GET_CAMPAIGN_AGGREGATED_METRICS_BY_CREATIVE)

  const [
    loadCampaignAggregatedMetricsByLineItem,
    {
      called: campaignAggregatedMetricsByLineItemCalled,
      loading: campaignAggregatedMetricsByLineItemLoading,
      error: campaignAggregatedMetricsByLineItemError,
      data: campaignAggregatedMetricsByLineItemData,
    },
  ] = useLazyQuery(GET_CAMPAIGN_AGGREGATED_METRICS_BY_LINE_ITEM)

  const [
    loadCampaignCreativesAggregatedMetrics,
    {
      called: campaignCreativesAggregatedMetricsCalled,
      loading: campaignCreativesAggregatedMetricsLoading,
      error: campaignCreativesAggregatedMetricsError,
      data: campaignCreativesAggregatedMetricsData,
    },
  ] = useLazyQuery(GET_CAMPAIGN_CREATIVES_AGGREGATED_METRICS)

  const [
    loadCampaignLineItemsAggregatedMetrics,
    {
      called: campaignLineItemsAggregatedMetricsCalled,
      loading: campaignLineItemsAggregatedMetricsLoading,
      error: campaignLineItemsAggregatedMetricsError,
      data: campaignLineItemsAggregatedMetricsData,
    },
  ] = useLazyQuery(GET_CAMPAIGN_LINE_ITEMS_AGGREGATED_METRICS)

  const [
    loadCampaignDailyMetrics,
    { called: campaignDailyMetricsCalled, loading: campaignDailyMetricsLoading, error: campaignDailyMetricsError, data: campaignDailyMetricsData },
  ] = useLazyQuery(GET_CAMPAIGN_DAILY_METRICS)

  const [
    loadCampaignCreativesDailyMetrics,
    {
      called: campaignCreativesDailyMetricsCalled,
      loading: campaignCreativesDailyMetricsLoading,
      error: campaignCreativesDailyMetricsError,
      data: campaignCreativesDailyMetricsData,
    },
  ] = useLazyQuery(GET_CAMPAIGN_CREATIVES_DAILY_METRICS)

  const [
    loadCampaignLineItemsDailyMetrics,
    {
      called: campaignLineItemsDailyMetricsCalled,
      loading: campaignLineItemsDailyMetricsLoading,
      error: campaignLineItemsDailyMetricsError,
      data: campaignLineItemsDailyMetricsData,
    },
  ] = useLazyQuery(GET_CAMPAIGN_LINE_ITEMS_DAILY_METRICS)

  // MAP TABLE METRICS QUERIES TO DISPLAY METRICS - START
  const tablesMetrics = useMemo(() => {
    if (
      campaignType &&
      kpis &&
      campaignAggregatedMetricsByCreativeCalled &&
      !campaignAggregatedMetricsByCreativeLoading &&
      campaignAggregatedMetricsByCreativeData &&
      campaignAggregatedMetricsByLineItemCalled &&
      !campaignAggregatedMetricsByLineItemLoading &&
      campaignAggregatedMetricsByLineItemData
    ) {
      return {
        creatives: unPackItemisedMetricsForTables({
          campaignKpis: kpis,
          campaignType,
          itemisedMetrics: (campaignAggregatedMetricsByCreativeData.getCampaignAggregatedMetricsByCreative as CampaignItemisedAggregatedMetrics)
            .itemisedMetrics,
        }),
        lineItems: unPackItemisedMetricsForTables({
          campaignKpis: kpis,
          campaignType,
          itemisedMetrics: (campaignAggregatedMetricsByLineItemData.getCampaignAggregatedMetricsByLineItem as CampaignItemisedAggregatedMetrics)
            .itemisedMetrics,
        }),
      }
    }
  }, [
    campaignType,
    kpis,
    campaignAggregatedMetricsByCreativeCalled,
    campaignAggregatedMetricsByCreativeLoading,
    campaignAggregatedMetricsByCreativeData,
    campaignAggregatedMetricsByLineItemCalled,
    campaignAggregatedMetricsByLineItemLoading,
    campaignAggregatedMetricsByLineItemData,
  ])
  // MAP TABLE METRICS QUERIES TO DISPLAY METRICS - END

  // CALL TRENDS GRAPH QUERY
  useEffect(() => {
    if (!campaignLast7DailyMetricsCalled && campaignType && clientId && integrationId && platform && campaignId && pixelIdsSets) {
      loadCampaignLast7DailyMetrics({
        variables: {
          campaignType,
          clientId,
          platformIntegrationId: integrationId,
          platform,
          platformAdvertiserId: advertiserId,
          platformCampaignId: campaignId,
          pixelIdsSets,
        },
      }).then()
    }
  }, [
    campaignLast7DailyMetricsCalled,
    campaignLast7DailyMetricsData,
    loadCampaignLast7DailyMetrics,
    campaignType,
    clientId,
    integrationId,
    platform,
    advertiserId,
    campaignId,
    pixelIdsSets,
  ])

  // CALL METRICS TABLES QUERIES
  useEffect(() => {
    const loadTablesData = async () => {
      await Promise.all([
        loadCampaignAggregatedMetricsByCreative({
          fetchPolicy: "network-only",
          variables: {
            campaignType,
            clientId,
            platformIntegrationId: integrationId,
            platform,
            platformAdvertiserId: advertiserId,
            platformCampaignId: campaignId,
            pixelIdsSets,
            creativeIds: creativesWithData.map((creative) => creative.platformCreativeId.id),
            dateFrom: format(selectedDates[0], "yyyy-MM-dd"),
            dateTo: format(selectedDates[1], "yyyy-MM-dd"),
          },
        }),
        loadCampaignAggregatedMetricsByLineItem({
          fetchPolicy: "network-only",
          variables: {
            campaignType,
            clientId,
            platformIntegrationId: integrationId,
            platform,
            platformAdvertiserId: advertiserId,
            platformCampaignId: campaignId,
            pixelIdsSets,
            lineItemIds: lineItemsWithData.map((lineItem) => lineItem.platformLineItemId.id),
            dateFrom: format(selectedDates[0], "yyyy-MM-dd"),
            dateTo: format(selectedDates[1], "yyyy-MM-dd"),
          },
        }),
      ])
    }
    if (creativesWithData.length > 0 && lineItemsWithData.length > 0 && selectedDates.length === 2) {
      loadTablesData().then()
    }
  }, [
    loadCampaignAggregatedMetricsByCreative,
    loadCampaignAggregatedMetricsByLineItem,
    campaignType,
    clientId,
    integrationId,
    platform,
    advertiserId,
    campaignId,
    pixelIdsSets,
    selectedDates,
    creativesWithData,
    lineItemsWithData,
  ])

  // CONTROLLER STATE - START
  const [campaignDashboardState, setCampaignDashboardState] = useState<CampaignDashboardStateItems>(defaultCampaignDashboardState)

  const { selectedCreativeIds, selectedLineItemIds, selectedSegmentsTab } = campaignDashboardState

  const onResetSelectedSegments = useCallback(() => {
    setCampaignDashboardState({
      ...campaignDashboardState,
      selectedCreativeIds: [],
      selectedLineItemIds: [],
    })
  }, [campaignDashboardState, setCampaignDashboardState])

  const onSelectedSegmentsListChange = useCallback(
    ({ segmentType, segments }: SelectedSegmentsListChangeProps) => {
      if (segmentType === SegmentType.Creatives) {
        setCampaignDashboardState({
          ...campaignDashboardState,
          selectedCreativeIds: segments,
          selectedLineItemIds: [],
        })
      }
      if (segmentType === SegmentType.LineItems) {
        setCampaignDashboardState({
          ...campaignDashboardState,
          selectedCreativeIds: [],
          selectedLineItemIds: segments,
        })
      }
    },
    [campaignDashboardState, setCampaignDashboardState]
  )

  const onSelectedSegmentsTableChange = useCallback(
    ({ segmentType, segments }: SelectedSegmentsTableChangeProps) => {
      if (tablesMetrics && tablesMetrics.creatives.length > 0 && tablesMetrics.lineItems.length > 0) {
        if (segmentType === SegmentType.Creatives && Object.keys(segments).length > 0) {
          const newSelectedCreatives = Object.keys(segments).reduce((result, row) => {
            result.push((tablesMetrics.creatives[Number(row)] as { itemId: string }).itemId)
            return result
          }, new Array<string>())

          if (campaignDashboardState.selectedCreativeIds !== newSelectedCreatives) {
            setCampaignDashboardState({
              ...campaignDashboardState,
              selectedCreativeIds: newSelectedCreatives,
              selectedLineItemIds: [],
            })
          }
        }

        if (segmentType === SegmentType.LineItems && Object.keys(segments).length > 0) {
          const newSelectedLineItems = Object.keys(segments).reduce((result, row) => {
            result.push((tablesMetrics.lineItems[Number(row)] as { itemId: string }).itemId)
            return result
          }, new Array<string>())

          if (campaignDashboardState.selectedLineItemIds !== newSelectedLineItems) {
            setCampaignDashboardState({
              ...campaignDashboardState,
              selectedCreativeIds: [],
              selectedLineItemIds: newSelectedLineItems,
            })
          }
        }
      }
    },
    [campaignDashboardState, tablesMetrics, setCampaignDashboardState]
  )

  const onSegmentsTabChange = useCallback(
    (index: number) => {
      if (index < Object.keys(SegmentType).length && index > -1) {
        setCampaignDashboardState({
          ...campaignDashboardState,
          selectedCreativeIds: [],
          selectedLineItemIds: [],
          selectedSegmentsTab: index,
        })
      }
    },
    [campaignDashboardState, setCampaignDashboardState]
  )
  // CONTROLLER STATE - END

  // CALL METRICS STRIP & MAIN GRAPH QUERIES
  useEffect(() => {
    const loadAllMetricsData = async () => {
      await Promise.all([
        loadCampaignAggregatedMetrics({
          fetchPolicy: "network-only",
          variables: {
            campaignType,
            clientId,
            platformIntegrationId: integrationId,
            platform,
            platformAdvertiserId: advertiserId,
            platformCampaignId: campaignId,
            pixelIdsSets,
            dateFrom: format(selectedDates[0], "yyyy-MM-dd"),
            dateTo: format(selectedDates[1], "yyyy-MM-dd"),
          },
        }),
        loadCampaignDailyMetrics({
          fetchPolicy: "network-only",
          variables: {
            campaignType,
            clientId,
            platformIntegrationId: integrationId,
            platform,
            platformAdvertiserId: advertiserId,
            platformCampaignId: campaignId,
            pixelIdsSets,
            dateFrom: format(selectedDates[0], "yyyy-MM-dd"),
            dateTo: format(selectedDates[1], "yyyy-MM-dd"),
          },
        }),
      ])
    }

    const loadCreativesMetricsData = async () => {
      await Promise.all([
        loadCampaignCreativesAggregatedMetrics({
          fetchPolicy: "network-only",
          variables: {
            campaignType,
            clientId,
            platformIntegrationId: integrationId,
            platform,
            platformAdvertiserId: advertiserId,
            platformCampaignId: campaignId,
            creativeIds: selectedCreativeIds,
            pixelIdsSets,
            dateFrom: format(selectedDates[0], "yyyy-MM-dd"),
            dateTo: format(selectedDates[1], "yyyy-MM-dd"),
          },
        }),
        loadCampaignCreativesDailyMetrics({
          fetchPolicy: "network-only",
          variables: {
            campaignType,
            clientId,
            platformIntegrationId: integrationId,
            platform,
            platformAdvertiserId: advertiserId,
            platformCampaignId: campaignId,
            creativeIds: selectedCreativeIds,
            pixelIdsSets,
            dateFrom: format(selectedDates[0], "yyyy-MM-dd"),
            dateTo: format(selectedDates[1], "yyyy-MM-dd"),
          },
        }),
      ])
    }

    const loadLineItemsMetricsData = async () => {
      await Promise.all([
        loadCampaignLineItemsAggregatedMetrics({
          fetchPolicy: "network-only",
          variables: {
            campaignType,
            clientId,
            platformIntegrationId: integrationId,
            platform,
            platformAdvertiserId: advertiserId,
            platformCampaignId: campaignId,
            lineItemIds: selectedLineItemIds,
            pixelIdsSets,
            dateFrom: format(selectedDates[0], "yyyy-MM-dd"),
            dateTo: format(selectedDates[1], "yyyy-MM-dd"),
          },
        }),
        loadCampaignLineItemsDailyMetrics({
          fetchPolicy: "network-only",
          variables: {
            campaignType,
            clientId,
            platformIntegrationId: integrationId,
            platform,
            platformAdvertiserId: advertiserId,
            platformCampaignId: campaignId,
            lineItemIds: selectedLineItemIds,
            pixelIdsSets,
            dateFrom: format(selectedDates[0], "yyyy-MM-dd"),
            dateTo: format(selectedDates[1], "yyyy-MM-dd"),
          },
        }),
      ])
    }

    if (campaignType && selectedCreativeIds.length === 0 && selectedLineItemIds.length === 0 && selectedDates.length === 2) {
      loadAllMetricsData().then()
    }

    if (selectedSegmentsTab === 0 && selectedCreativeIds.length > 0 && selectedLineItemIds.length === 0 && selectedDates.length === 2) {
      loadCreativesMetricsData().then()
    }

    if (selectedSegmentsTab === 1 && selectedLineItemIds.length > 0 && selectedCreativeIds.length === 0 && selectedDates.length === 2) {
      loadLineItemsMetricsData().then()
    }
  }, [
    loadCampaignAggregatedMetrics,
    loadCampaignDailyMetrics,
    loadCampaignCreativesAggregatedMetrics,
    loadCampaignCreativesDailyMetrics,
    loadCampaignLineItemsAggregatedMetrics,
    loadCampaignLineItemsDailyMetrics,
    campaignType,
    clientId,
    platform,
    integrationId,
    advertiserId,
    campaignId,
    pixelIdsSets,
    selectedDates,
    selectedSegmentsTab,
    selectedCreativeIds,
    selectedLineItemIds,
  ])

  // MAP METRICS QUERIES TO DISPLAY METRICS - START
  // MAP TRENDS GRAPHS METRICS
  const trendsGraphsMetrics = useMemo(() => {
    if (campaignLast7DailyMetricsCalled && campaignLast7DailyMetricsData && campaignType && kpis) {
      return unPackDailyMetricsForTrendGraphs(campaignLast7DailyMetricsData.getCampaignLast7DailyMetrics.dailyMetrics, campaignType, kpis)
    }
  }, [kpis, campaignLast7DailyMetricsCalled, campaignLast7DailyMetricsData, campaignType])

  // MAP AGGREGATED METRICS
  const aggregatedMetrics = useMemo(() => {
    if (
      selectedSegmentsTab === 0 &&
      selectedCreativeIds.length > 0 &&
      campaignCreativesAggregatedMetricsCalled &&
      campaignCreativesAggregatedMetricsData &&
      campaignType &&
      kpis
    ) {
      return unPackMetricsForMetricsStrip(campaignCreativesAggregatedMetricsData.getCampaignCreativesAggregatedMetrics.metrics, kpis, campaignType)
    }
    if (
      selectedSegmentsTab === 1 &&
      selectedLineItemIds.length > 0 &&
      campaignLineItemsAggregatedMetricsCalled &&
      campaignLineItemsAggregatedMetricsData &&
      campaignType &&
      kpis
    ) {
      return unPackMetricsForMetricsStrip(campaignLineItemsAggregatedMetricsData.getCampaignLineItemsAggregatedMetrics.metrics, kpis, campaignType)
    }
    if (
      selectedCreativeIds.length === 0 &&
      selectedLineItemIds.length === 0 &&
      campaignAggregatedMetricsCalled &&
      campaignAggregatedMetricsData &&
      campaignType &&
      kpis
    ) {
      return unPackMetricsForMetricsStrip(campaignAggregatedMetricsData.getCampaignAggregatedMetrics.metrics, kpis, campaignType)
    }
    return []
  }, [
    selectedSegmentsTab,
    selectedCreativeIds,
    selectedLineItemIds,
    campaignType,
    kpis,
    campaignCreativesAggregatedMetricsCalled,
    campaignLineItemsAggregatedMetricsCalled,
    campaignAggregatedMetricsCalled,
    campaignCreativesAggregatedMetricsData,
    campaignLineItemsAggregatedMetricsData,
    campaignAggregatedMetricsData,
  ])

  // MAP MAIN GRAPH METRICS
  const mainGraphMetrics = useMemo(() => {
    if (selectedCreativeIds.length > 0 && campaignCreativesDailyMetricsCalled && campaignCreativesDailyMetricsData) {
      return campaignCreativesDailyMetricsData.getCampaignCreativesDailyMetrics.dailyMetrics
    }
    if (selectedLineItemIds.length > 0 && campaignLineItemsDailyMetricsCalled && campaignLineItemsDailyMetricsData) {
      return campaignLineItemsDailyMetricsData.getCampaignLineItemsDailyMetrics.dailyMetrics
    }
    if (selectedCreativeIds.length === 0 && selectedLineItemIds.length === 0 && campaignDailyMetricsCalled && campaignDailyMetricsData) {
      return campaignDailyMetricsData.getCampaignDailyMetrics.dailyMetrics
    }
    return []
  }, [
    selectedCreativeIds,
    selectedLineItemIds,
    campaignDailyMetricsCalled,
    campaignCreativesDailyMetricsCalled,
    campaignLineItemsDailyMetricsCalled,
    campaignDailyMetricsData,
    campaignCreativesDailyMetricsData,
    campaignLineItemsDailyMetricsData,
  ])
  // MAP METRICS QUERIES TO DISPLAY METRICS - END

  const creativesSelectedMainGraphNoData =
    selectedCreativeIds.length > 0 && campaignCreativesDailyMetricsCalled && !campaignCreativesDailyMetricsLoading && mainGraphMetrics.length === 0
  const lineItemsSelectedMainGraphNoData =
    selectedLineItemIds.length > 0 && campaignLineItemsDailyMetricsCalled && !campaignLineItemsDailyMetricsLoading && mainGraphMetrics.length === 0
  const nothingSelectedMainGraphNoData =
    selectedCreativeIds.length === 0 &&
    selectedLineItemIds.length === 0 &&
    campaignDailyMetricsCalled &&
    !campaignDailyMetricsLoading &&
    mainGraphMetrics.length === 0

  const mainGraphNoData = creativesSelectedMainGraphNoData || lineItemsSelectedMainGraphNoData || nothingSelectedMainGraphNoData

  // TILES COMPONENTS, STATE & PROPS - START
  const tiles:
    | StringKeyObject<TileComponent<CampaignDashboardStateController, GridState, object, TileProps<CampaignDashboardStateController, GridState, object>>>
    | undefined = useMemo(() => {
    const campaignDashboardController = {
      campaignType,
      campaignDashboardState,
      campaignCurrencySymbol: currency ? getSymbolFromCurrency(currency) : "£",
      onResetSelectedSegments,
      onSelectedSegmentsListChange,
      onSelectedSegmentsTableChange,
      onSegmentsTabChange,
    }

    if (
      campaignCreativesWithDeliveryCalled &&
      campaignLineItemsWithDeliveryCalled &&
      campaignAggregatedMetricsCalled &&
      campaignDailyMetricsCalled &&
      campaignLast7DailyMetricsCalled &&
      mainGraphMetrics.length > 0 &&
      trendsGraphsMetrics &&
      aggregatedMetrics &&
      selectableMetrics.length > 0 &&
      creativesWithData &&
      lineItemsWithData &&
      tablesMetrics &&
      tablesMetrics.creatives.length > 0 &&
      tablesMetrics.lineItems.length > 0 &&
      kpis
    ) {
      return {
        trends: {
          component: TrendsStrip as FC<TileProps<CampaignDashboardStateController, GridState, object>>,
          props: {
            dashboardController: campaignDashboardController,
            gridState: exampleGridState,
            tileData: trendsGraphsMetrics,
            queryDetails: {
              error: campaignLast7DailyMetricsError,
              loading: campaignLast7DailyMetricsLoading,
            },
          },
        },
        metrics: {
          component: MetricsStrip as FC<TileProps<CampaignDashboardStateController, GridState, object>>,
          props: {
            dashboardController: campaignDashboardController,
            gridState: exampleGridState,
            tileData: aggregatedMetrics,
            queryDetails: {
              error: campaignAggregatedMetricsError || campaignCreativesAggregatedMetricsError || campaignLineItemsAggregatedMetricsError,
              loading: campaignAggregatedMetricsLoading || campaignCreativesAggregatedMetricsLoading || campaignLineItemsAggregatedMetricsLoading,
            },
          },
        },
        drillDown: {
          component: TabsLists as FC<TileProps<CampaignDashboardStateController, GridState, object>>,
          props: {
            dashboardController: campaignDashboardController,
            gridState: exampleGridState,
            tileData: {
              creatives: {
                title: "Creatives",
                listItems: creativesWithData,
              },
              lineItems: {
                title: "Line Items",
                listItems: lineItemsWithData,
              },
            },
          },
        },
        graphs: {
          component: SelectableMetricsGraph as FC<TileProps<CampaignDashboardStateController, GridState, object>>,
          props: {
            dashboardController: campaignDashboardController,
            gridState: exampleGridState,
            tileData: {
              campaignKpis: kpis,
              metrics: mainGraphMetrics,
              selectableMetrics,
            },
            queryDetails: {
              error: campaignDailyMetricsError || campaignCreativesDailyMetricsError || campaignLineItemsDailyMetricsError,
              loading: campaignDailyMetricsLoading || campaignCreativesDailyMetricsLoading || campaignLineItemsDailyMetricsLoading,
            },
          },
        },
        tables: {
          component: TablesInTabs as FC<TileProps<CampaignDashboardStateController, GridState, object>>,
          props: {
            dashboardController: campaignDashboardController,
            gridState: exampleGridState,
            tileData: {
              creatives: {
                title: "Creatives",
                tableData: tablesMetrics.creatives,
              },
              lineItems: {
                title: "Line Items",
                tableData: tablesMetrics.lineItems,
              },
            },
            queryDetails: {
              error: campaignAggregatedMetricsByCreativeError || campaignAggregatedMetricsByLineItemError,
            },
          },
        },
      }
    }
    return undefined
  }, [
    campaignCreativesWithDeliveryCalled,
    campaignLineItemsWithDeliveryCalled,
    campaignAggregatedMetricsCalled,
    campaignDailyMetricsCalled,
    campaignLast7DailyMetricsCalled,
    campaignAggregatedMetricsByCreativeError,
    campaignAggregatedMetricsByLineItemError,
    campaignAggregatedMetricsError,
    campaignAggregatedMetricsLoading,
    campaignCreativesAggregatedMetricsError,
    campaignCreativesAggregatedMetricsLoading,
    campaignCreativesDailyMetricsError,
    campaignCreativesDailyMetricsLoading,
    campaignDailyMetricsError,
    campaignDailyMetricsLoading,
    campaignLast7DailyMetricsError,
    campaignLast7DailyMetricsLoading,
    campaignLineItemsAggregatedMetricsError,
    campaignLineItemsAggregatedMetricsLoading,
    campaignLineItemsDailyMetricsError,
    campaignLineItemsDailyMetricsLoading,
    campaignDashboardState,
    campaignType,
    currency,
    onResetSelectedSegments,
    onSelectedSegmentsListChange,
    onSelectedSegmentsTableChange,
    onSegmentsTabChange,
    mainGraphMetrics,
    trendsGraphsMetrics,
    aggregatedMetrics,
    selectableMetrics,
    creativesWithData,
    lineItemsWithData,
    tablesMetrics,
    kpis,
  ])
  // TILES COMPONENTS, STATE & PROPS - END

  const fallbackErrorMessage = `There is a problem with Campaign Reports for campaign ${name}.`
  const fallbackErrorEmailSubject = `Unhandled Error in ${name} Campaign Report`
  const fallbackErrorEmailBody = `
Hello 59A Helpdesk,
  
I encountered a problem in Campaign Reports when doing...
`

  const permissionsErrorMessage = `You do not have permission to view this page.`
  const permissionsErrorEmailSubject = `Permissions Issue in Campaign Report`
  const permissionsErrorEmailBody = `
Hello 59A Helpdesk,

I encountered a permissions issue in Campaign Report.
`

  return (
    <MainLayout isErrored={errored} user={user} signOut={signOut} breadcrumbItems={breadcrumbItems}>
      {permissionsLoading && <Loading />}
      {!permissionsLoading && canView(permissions, platformCampaignResourceUri) && (
        <>
          {errored || setupDataErrored ? (
            <div>Error</div>
          ) : (
            <VStack align="flex-start" h="100%">
              <Button onClick={() => navigate(-1)} isInline={true} isSecondary={true}>
                Back
              </Button>
              {kpis && (
                <Grid
                  width="container.xl"
                  maxWidth="container.xl"
                  height="54px"
                  columnGap="20px"
                  templateAreas={`"info info info datepicker datepicker"`}
                  templateColumns="repeat(5, minmax(0, 1fr))"
                  mb={4}
                >
                  <GridItem area="info">
                    {managed !== undefined && name && (
                      <CampaignInfo isManaged={managed} toggleCampaignManagement={handleToggleManageCampaign} campaignName={name} platform={platform} />
                    )}
                  </GridItem>
                  <GridItem area="datepicker">
                    <Tile px={3} py={0}>
                      <RangeDatePicker selectedDates={selectedDates} onDateChange={setSelectedDates} usePortal={true} />
                    </Tile>
                  </GridItem>
                </Grid>
              )}
              {kpis && setupDataLoaded && (
                <ErrorBoundary
                  fallback={
                    <ErrorFallBack marginTop={6} message={fallbackErrorMessage} emailSubject={fallbackErrorEmailSubject} emailBody={fallbackErrorEmailBody} />
                  }
                  onError={logUnhandledError}
                >
                  {selectedDates.length === 2 && mainGraphNoData ? (
                    <CampaignDashboardNoData />
                  ) : tiles && Object.keys(tiles).length !== 0 ? (
                    <TilesGridLayout tiles={tiles} />
                  ) : null}
                </ErrorBoundary>
              )}
            </VStack>
          )}
        </>
      )}
      {!permissionsLoading && !canView(permissions, platformCampaignResourceUri) && (
        <ErrorFallBack marginTop={6} message={permissionsErrorMessage} emailSubject={permissionsErrorEmailSubject} emailBody={permissionsErrorEmailBody} />
      )}
    </MainLayout>
  )
}

export default CampaignDashboardPage
