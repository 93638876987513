import { gql } from "@apollo/client"

export const CREATE_CLIENT = gql`
  mutation createClient($name: String!, $type: ClientType!, $clientContact: String!, $billingContact: String!) {
    createClient(name: $name, type: $type, clientContact: $clientContact, billingContact: $billingContact) {
      ... on DirectClient {
        clientId
        name
      }

      ... on AgencyClient {
        clientId
        name
      }

      ... on Fifty9AClient {
        clientId
        name
      }
    }
  }
`
