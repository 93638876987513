import React, { createContext, FC, useEffect, useState } from "react"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { Flex, useDisclosure } from "@chakra-ui/react"
import { ResourceActions } from "@fifty9a/utils-api-auth"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useLocation } from "react-router-dom"
import Button from "../../../components/Button"
import ErrorFallBack from "../../../components/ErrorFallback"
import { TransferListsData } from "../../../components/forms/TransferLists"
import Modal from "../../../components/Modal"
import RolesTable from "../../../components/tables/RolesTable"
import { RoleTableRowData } from "../../../components/tables/RolesTable/RolesTable"
import { MutationUpdateRoleUsersArgs, RoleUserInput, RoleWithPermissions, RoleWithUsers, UserProfileWithRoles } from "../../../generated/graphql"
import { GET_ROLE_WITH_PERMISSIONS, GET_ROLES, GET_USER_PROFILES, UPSERT_ROLE } from "../../../graphql"
import { UPDATE_ROLE_USERS } from "../../../graphql/mutations/updateRoleUsers"
import { logError } from "../../../log"
import { UserProfile } from "../../../models/AuthState"
import { Optional } from "../../../types"
import { kebabToCamelCase } from "../../../utils/stringUtils"
import ManageRoleUsersForm, { manageRoleUsersFormSchema } from "../components/ManageRoleUsersForm"
import RoleSetupForm, { Permissions, roleFormSchema, RoleFormValues } from "../components/RoleSetupForm"
import { is59AClient, ROLES_TAB_HASH } from "../UserManagement"

type RolesTabProps = {
  user: UserProfile
}

export const RolesTabContext = createContext<TransferListsData | undefined>(undefined)

const RolesTab: FC<RolesTabProps> = ({ user }) => {
  const { hash } = useLocation()

  const [rolesData, setRolesData] = useState<Array<RoleWithUsers>>()
  const [roleName, setRoleName] = useState("")
  const [isCreateRole, setIsCreateRole] = useState(false)

  const [selectedRole, setSelectedRole] = useState<RoleTableRowData>()
  const [allUsers, setAllUsers] = useState<UserProfileWithRoles[]>([])
  const [availableUsers, setAvailableUsers] = useState<UserProfileWithRoles[]>([])
  const [selectedUsers, setSelectedUsers] = useState<UserProfileWithRoles[]>([])
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([])

  const { data: getUserProfilesData, refetch: refetchGetUserProfiles } = useQuery(GET_USER_PROFILES, { fetchPolicy: "network-only" })
  const { loading: getRolesLoading, error: getRolesError, data: getRolesData, refetch: refetchGetRoles } = useQuery(GET_ROLES, { fetchPolicy: "network-only" })
  const [getRoleWithPermissions] = useLazyQuery(GET_ROLE_WITH_PERMISSIONS, { fetchPolicy: "network-only" })
  const [upsertRole, { loading: upsertRoleLoading, error: upsertRoleError, reset: resetUpsertRole }] = useMutation(UPSERT_ROLE, { refetchQueries: [GET_ROLES] })
  const [updateRoleUsers, { error: updateRoleUsersError, reset: resetUpdateRoleUsers }] = useMutation(UPDATE_ROLE_USERS)

  const defaultRoleFormValues = {
    clientId: Number(user.clientId),
    roleId: undefined,
    name: undefined,
    description: undefined,
    permissions: {
      client: [],
      platformIntegration: [],
      platformAdvertiser: [],
      platformCampaign: [],
      user: [],
    },
  }

  const {
    reset: resetRoleForm,
    control,
    handleSubmit,
    watch,
    setValue: setRoleFormValue,
  } = useForm<RoleFormValues>({
    resolver: zodResolver(roleFormSchema),
    shouldUnregister: false,
    defaultValues: defaultRoleFormValues,
  })

  const watchClientId = watch("clientId")

  const defaultManageRoleUsersFormValues = {
    clientId: Number(user.clientId),
    roleId: undefined,
    users: [],
  }

  const {
    reset: resetManageRoleUsersForm,
    handleSubmit: handleManageRoleUsersForm,
    setValue: setManageRoleUsersFormValue,
  } = useForm<MutationUpdateRoleUsersArgs>({
    resolver: zodResolver(manageRoleUsersFormSchema),
    defaultValues: defaultManageRoleUsersFormValues,
  })

  const { isOpen: roleFormIsOpen, onClose: roleFormOnClose, onOpen: roleFormOnOpen } = useDisclosure()
  const { isOpen: manageRoleUsersFormIsOpen, onClose: manageRoleUsersOnClose, onOpen: manageRoleUsersOnOpen } = useDisclosure()

  const onFinishUpsertRoleHandler = async () => {
    roleFormOnClose()
    resetUpsertRole()
    resetRoleForm()
    await refetchGetRoles()
  }

  const upsertRoleHandler = async (data: RoleFormValues) => {
    setRoleName(data.name ?? "")

    const { permissions, ...rest } = data

    const permissionsToSubmit =
      is59AClient(watchClientId) && user.is59A
        ? permissions
        : {
            client: [],
            platformIntegration: permissions?.platformIntegration,
            platformAdvertiser: permissions?.platformAdvertiser,
            platformCampaign: permissions?.platformCampaign,
            user: permissions?.user,
          }

    try {
      await upsertRole({
        variables: {
          ...rest,
          permissions: permissionsToSubmit,
        },
      })

      onFinishUpsertRoleHandler()
    } catch (error) {
      logError(error)
    }
  }

  const createRoleFormOnOpen = () => {
    setIsCreateRole(true)
    resetRoleForm(getInitialFormValues(user, undefined))
    roleFormOnOpen()
  }

  const onEditRoleHandler = async (role: RoleWithUsers) => {
    setIsCreateRole(false)
    const roleWithPermissions = await getRoleWithPermissions({
      variables: {
        clientId: role.clientId,
        roleId: role.roleId,
      },
    })
    resetRoleForm(getInitialFormValues(user, roleWithPermissions.data.getRoleWithPermissions))
    roleFormOnOpen()
  }

  const onManageUsersOpenHandler = async (row: RoleTableRowData) => {
    const roleAssignedUsers = rolesData?.find((role: RoleWithUsers) => role.roleId === row.roleId)?.users as UserProfileWithRoles[]

    const getRemainingAvailableUsers = () => {
      // get distinct items
      return allUsers.filter((item) => item.clientId === row.clientId).filter(({ userId: id1 }) => !roleAssignedUsers.some(({ userId: id2 }) => id2 === id1))
    }

    setSelectedUsers(roleAssignedUsers)
    setAvailableUsers(getRemainingAvailableUsers())

    setManageRoleUsersFormValue("roleId", row.roleId)
    setManageRoleUsersFormValue("clientId", row.clientId)
    setSelectedRole(row)

    manageRoleUsersOnOpen()
  }

  const closeManageRoleUsersFormHandler = async () => {
    manageRoleUsersOnClose()
    resetManageRoleUsersForm()
    resetUpdateRoleUsers()
    await refetchGetUserProfiles()
    await refetchGetRoles()
  }

  const updateRoleUsersHandler = async (data: MutationUpdateRoleUsersArgs) => {
    await updateRoleUsers({
      variables: {
        ...data,
      },
    })

    await closeManageRoleUsersFormHandler()
  }

  useEffect(() => {
    if (getUserProfilesData) {
      setAllUsers(getUserProfilesData.getUserProfiles)
      setAvailableUsers(getUserProfilesData.getUserProfiles)
    }
  }, [getUserProfilesData])

  useEffect(() => {
    if (getRolesData) {
      setRolesData(getRolesData.getRoles)
    }
  }, [getRolesData])

  useEffect(() => {
    if (!selectedUserIds) {
      setManageRoleUsersFormValue("users", [])
    } else {
      const userData: RoleUserInput[] = []
      selectedUserIds.forEach((id) => {
        const user = allUsers.find((u) => u.userId === id)
        if (!user) return

        userData.push({
          clientId: user.clientId,
          userId: user.userId,
        })
      })
      setManageRoleUsersFormValue("users", userData)
    }
  }, [allUsers, selectedUserIds, setManageRoleUsersFormValue])

  useEffect(() => {
    if (hash === ROLES_TAB_HASH) {
      ;(async () => {
        await refetchGetRoles()
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash])

  const userIsAdministrator = user.roles.some((role) => role.isAdmin)

  const rolesTabContextStore: TransferListsData = {
    canMakeAdminChanges: userIsAdministrator && (selectedRole?.isAdmin ?? false),
    userEmail: user.email,
    available: {
      items: availableUsers.map((item) => ({
        name: item.email,
        value: item.userId,
      })),
    },
    selected: {
      items: selectedUsers.map((item) => ({
        name: item.email,
        value: item.userId,
      })),
      setItems: setSelectedUserIds,
    },
  }

  const fallbackErrorMessage = `There is a problem with the Roles Tab.`
  const fallbackErrorEmailSubject = `Unhandled Error in Roles Tab`
  const fallbackErrorEmailBody = `
Hello 59A Helpdesk,
  
I encountered a problem in the Roles Tab when doing...
`
  const title = `${isCreateRole ? "Create New" : "Edit Existing"} Role`

  return (
    <>
      {getRolesError ? (
        <ErrorFallBack marginTop={6} message={fallbackErrorMessage} emailSubject={fallbackErrorEmailSubject} emailBody={fallbackErrorEmailBody} />
      ) : (
        <>
          <Flex justify="start" py={3} mb="-3.25rem">
            <Button onClick={createRoleFormOnOpen}>Create Role</Button>
          </Flex>
          <Modal hasForm isCentered isOpen={roleFormIsOpen} onClose={onFinishUpsertRoleHandler} title={title} width="container.sm">
            <RoleSetupForm
              onSubmitHandler={upsertRoleHandler}
              error={upsertRoleError}
              submitLoading={upsertRoleLoading}
              roleName={roleName}
              watchClientId={watchClientId}
              user={user}
              control={control}
              setValue={setRoleFormValue}
              handleSubmit={handleSubmit}
              isCreateRole={isCreateRole}
            />
          </Modal>
          <Modal
            hasForm
            isCentered
            isOpen={manageRoleUsersFormIsOpen}
            onClose={closeManageRoleUsersFormHandler}
            title={`Manage users for ${selectedRole}`}
            width="container.lg"
          >
            <RolesTabContext.Provider value={rolesTabContextStore}>
              <ManageRoleUsersForm
                onSubmitHandler={updateRoleUsersHandler}
                error={updateRoleUsersError}
                selectedRole={selectedRole}
                handleSubmit={handleManageRoleUsersForm}
                caller="RolesTab"
              />
            </RolesTabContext.Provider>
          </Modal>
          <RolesTable //
            user={user}
            data={rolesData}
            isLoading={getRolesLoading}
            onEdit={onEditRoleHandler}
            refetchData={refetchGetRoles}
            onManageUsersOpenHandler={onManageUsersOpenHandler}
          />
        </>
      )}
    </>
  )
}

const getInitialFormValues = (user: UserProfile, data: Optional<RoleWithPermissions>): RoleFormValues => {
  const defaultPermissions = Object.fromEntries(Object.entries(ResourceActions).map(([key, _]) => [kebabToCamelCase(key), []]))

  return {
    clientId: data?.clientId ?? Number(user.clientId),
    roleId: data?.roleId,
    name: data?.name,
    description: data?.description,
    permissions: data?.permissions
      ? {
          client: data.permissions.client,
          user: data.permissions.user,
          platformAdvertiser: data.permissions.platformAdvertiser,
          platformCampaign: data.permissions.platformCampaign,
          platformIntegration: data.permissions.platformIntegration,
        }
      : (defaultPermissions as unknown as Permissions),
  }
}

export default RolesTab
