import React, { FC } from "react"
import { Divider, Heading, HStack, Text } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { z } from "zod"
import Button from "../../../components/Button"
import ErrorText from "../../../components/forms/ErrorText"
import PercentageInput from "../../../components/forms/PercentageInput"
import SelectInput from "../../../components/forms/SelectInput"
import WizardNavigateNextButton from "../../../components/WizardNavigateNextButton"
import Env, { EnvKey } from "../../../Env"
import { CampaignType, Platform } from "../../../generated/graphql"
import { useCampaignParams } from "../../../hooks/navigationHooks"
import { useYouTubeEnabled } from "../../../hooks/useYouTubeEnabled"
import { WizardStepProps } from "../../../layouts/Wizard/WizardLayout"
import { enumToLabelValueArray } from "../../../utils/enumUtils"
import SetupCampaignData, { Step1Data } from "./SetupCampaignData"

interface FormValues {
  campaignType: CampaignType
  margin: string
  minMargin?: string
  maxMargin?: string
}

const MAX_MARGIN = 2000

const allCampaignTypeOptions = enumToLabelValueArray(CampaignType)

const step1FormSchema = z
  .object({
    campaignType: z.nativeEnum(CampaignType, {
      required_error: "Campaign Type not provided",
      invalid_type_error: "Campaign Type must be a CampaignType",
    }),
    margin: z
      .string({
        required_error: "Initial margin value not provided",
      })
      .trim(),
    minMargin: z
      .string({
        required_error: "Min margin value not provided",
      })
      .trim(),
    maxMargin: z
      .string({
        required_error: "Max margin value not provided",
      })
      .trim(),
  })
  .refine((schema) => Number(schema.margin) >= 0, `Initial margin must be between 0 and ${MAX_MARGIN}`)
  .refine((schema) => Number(schema.minMargin) <= Number(schema.margin), "Minimum margin must be between 0 and the initial margin")
  .refine((schema) => Number(schema.maxMargin) >= Number(schema.margin), `Maximum margin must be between the initial margin and ${MAX_MARGIN}`)

interface Step1FormProps {
  initialValues: FormValues
  submitAndNext: (values: FormValues) => void
}

const Step1Form: FC<Step1FormProps> = ({ initialValues, submitAndNext }) => {
  const navigate = useNavigate()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      ...initialValues,
    },
    resolver: zodResolver(step1FormSchema),
  })

  const { clientId, platform } = useCampaignParams()

  const youTubeEnabled =
    useYouTubeEnabled({
      clientId: Number(clientId),
      stage: Env.requireString(EnvKey.Stage),
    }) && platform === Platform.Dv360

  const campaignTypeOptions = youTubeEnabled ? allCampaignTypeOptions : allCampaignTypeOptions.filter((option) => option.value !== CampaignType.Youtube)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const errorMessage = (errors as any)[""] && "message" in (errors as any)[""] ? (errors as any)[""].message : ""

  return (
    <>
      <Button onClick={() => navigate(-1)} isInline={true} isSecondary={true} my={3}>
        Back
      </Button>
      <form onSubmit={handleSubmit(submitAndNext)}>
        <SelectInput //
          options={campaignTypeOptions}
          name="campaignType"
          label="Campaign Type"
          helperText="Please select whether the campaign is display or video."
          tooltip="This is whether the campaign is display or video."
          control={control}
          zIndex={7}
        />
        <Divider />
        <Heading paddingTop={2} size="md">
          Margin settings
        </Heading>
        <Text fontSize={"sm"} paddingBottom={4} paddingTop={2} color={"gray.600"}>
          Margin is calculated on a cost plus basis.
        </Text>
        <HStack align="flex-end" justifyContent="flex-start">
          <PercentageInput //
            name="margin"
            control={control}
            label={"Initial value"}
            max={MAX_MARGIN}
            tooltip="The initial margin value for this campaign. Set it to 0 if you do not wish to use margin."
          />
          <PercentageInput //
            name="minMargin"
            control={control}
            label={"Min"}
            max={MAX_MARGIN}
            tooltip="The minimum margin constraint for this campaign"
          />
          <PercentageInput //
            name="maxMargin"
            control={control}
            label={"Max"}
            max={MAX_MARGIN}
            tooltip="The maximum margin constraint for this campaign"
          />
        </HStack>
        <ErrorText message={errorMessage} />
        <WizardNavigateNextButton next={{ type: "submit" }} />
      </form>
    </>
  )
}

interface Step1Props extends WizardStepProps<SetupCampaignData, Step1Data> {
  currencySymbol: string
  dataFinalised: boolean
}

const Step1: FC<Step1Props> = ({ onNext, stepIndex, wizardData, dataFinalised }) => {
  // Get pre-existing data from top-level wizard, if present
  const stepData = wizardData[stepIndex] as Step1Data

  const initialValues = getInitialFormValues(stepData)

  const submitAndNext = (values: FormValues) => onNext(buildStepData(values))

  return dataFinalised ? <Step1Form initialValues={initialValues} submitAndNext={submitAndNext} /> : null
}

const getInitialFormValues = (data: Step1Data): FormValues => ({
  campaignType: data?.campaignType,
  margin: data?.margin,
  minMargin: data?.minMargin,
  maxMargin: data?.maxMargin,
})

const buildStepData = (values: FormValues): Step1Data => ({
  campaignType: values.campaignType,
  margin: values.margin,
  minMargin: values.minMargin,
  maxMargin: values.maxMargin,
})

export default Step1
