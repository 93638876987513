import { gql } from "@apollo/client"

export const GET_CLIENTS = gql`
  query GetClients {
    getClients {
      ... on DirectClient {
        clientId
        name
        clientContact
        billingContact
      }

      ... on AgencyClient {
        clientId
        name
        clientContact
        billingContact
      }

      ... on Fifty9AClient {
        clientId
        name
      }
    }
  }
`
